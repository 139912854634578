import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LayoutEng from "../components/LayoutEng"
import SEO from "../components/SEO"

const BlogEngTemplate = ({ data, pageContext }) => {
  const { html } = data.markdownRemark
  const { title, author, date, featuredImg } = data.markdownRemark.frontmatter
  return (
    <LayoutEng>
      <SEO title={pageContext.slug} description={pageContext.description} />
      <main className="page blog-page">
        <h1><strong>{title}</strong></h1>
        <p>date: {date}</p>
        <p>author: {author}</p>
        <div className="tagImg">
        <GatsbyImage
        image={getImage(featuredImg.childImageSharp.gatsbyImageData)}
         />
        </div>
        <div className="pageText" dangerouslySetInnerHTML={{ __html: html }} />
        <div className="backBtn">
              <Link to="/en/blog/#blogsEng"  className="btn">
                &laquo; Back 
              </Link>
        </div>
      </main>
    </LayoutEng>
  )
}

export const query = graphql` 
query BlogEngTemplate($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      slug
      author
      date
      description
      title
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            transformOptions: {fit: CONTAIN}
            width: 800
          )
        }
      }
    }
  }
}
`


export default BlogEngTemplate
